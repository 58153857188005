import React from "react"

const HTMLElements = [
  "a",
  "abbr",
  "article",
  "aside",
  "audio",
  "b",
  "base",
  "big",
  "blockquote",
  "body",
  "br",
  "button",
  "caption",
  "cite",
  "code",
  "dd",
  "del",
  "details",
  "dfn",
  "dialog",
  "div",
  "dl",
  "dt",
  "em",
  "embed",
  "fieldset",
  "figcaption",
  "figure",
  "footer",
  "form",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "head",
  "header",
  "hr",
  "i",
  "img",
  "input",
  "ins",
  "kbd",
  "label",
  "legend",
  "li",
  "link",
  "main",
  "map",
  "mark",
  "meter",
  "nav",
  "object",
  "ol",
  "optgroup",
  "option",
  "output",
  "p",
  "param",
  "picture",
  "pre",
  "progress",
  "q",
  "rp",
  "rt",
  "s",
  "samp",
  "section",
  "select",
  "small",
  "source",
  "span",
  "strong",
  "style",
  "sub",
  "summary",
  "sup",
  "svg",
  "template",
  "textarea",
  "tfoot",
  "th",
  "thead",
  "time",
  "title",
  "track",
  "u",
  "ul",
]

const TextElements = [
  "b",
  "del",
  "em",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "i",
  "ins",
  "mark",
  "p",
  "small",
  "strong",
  "sub",
  "sup"
]

const headerMatch = /h[1-9]/gm

const safariFixedOverflowStyle = { display: 'inline-block', overflow: 'hidden' }

const addPrimaryContentBlockStyles = (elem, props) => {
  return React.createElement(elem, {
    style: { color: '#ffffff' },
    ...props
  })
}

const addOverflowStyles = (elem, props) => {
  return React.createElement(elem, {
    style: { ...(elem !== 'a' && safariFixedOverflowStyle), ...(headerMatch.test(elem) && { width: '100%' }) },
    ...props
  })
}

export const overflowComponents = Object.fromEntries(
  HTMLElements.map((elem) => [elem, ({ node, ...props }) => addOverflowStyles(elem, props)])
)

export const listicleContentBlockComponents = Object.fromEntries(
  TextElements.map((elem) => [elem, ({ node, ...props }) => addPrimaryContentBlockStyles(elem, props)])
)